<template>
  <q-dialog v-model="dialogState" v-if="mobileAppData">
    <q-card class="install-app-qr-modal">
      <q-card-section class="row items-center q-pb-none">
        <div class="text-h6 qr-modal-title text-center">
          Scan this QR Code to install Our Android Mobile App
        </div>

        <q-btn
          icon="close"
          class="qr-modal-close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-card-section class="flex justify-center q-pb-lg">
        <div class="qr-arrow-left">
          <q-img src="images/downarrow-left.svg" width="20" height="120" />
        </div>
        <div class="qr-code q-mb-md">
          <qrcode-vue :value="mobileAppData.url" size="200"></qrcode-vue>
          <!-- <q-img
            src="https://via.placeholder.com/200"
            spinner-color="primary"
            class="thumbside"
          /> -->
        </div>
        <div class="qr-arrow-right">
          <q-img src="images/downarrow-right.svg" width="20" height="120" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AppBarcode',
  components: {
    QrcodeVue,
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('AppBarcode')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'AppBarcode',
          val,
        })
      },
    },
    ...mapGetters('common', ['mobileAppData']),
  },
}
</script>
